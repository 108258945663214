<template>
  <div class="flex flex-col p-4">
    <div class="font-semibold text-center">Ticket Form</div>
    <div class="text-center text-hgray8 text-sm">
      Complete the ticket data in each column provided
    </div>

    <div class="flex flex-col mt-24 items-center justify-center">
      <img :src="NoTicket" />
      <div class="font-Roboto text-2xl text-hgray8 mt-5">Choose ticket type first!</div>
      <div class="text-hgray7 text-sm font-Robot mt-2 text-center">
        To show ticket form, you should select ticket type on the side first, and make sure you
        choose the right ticket type.
      </div>
    </div>
  </div>
</template>

<script>
import NoTicket from '@/assets/images/no-ticket.png'

export default {
  name: 'open-intro',
  data() {
    return {
      NoTicket
    }
  }
}
</script>
